import React, { Fragment, useState, useEffect } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { toast } from "react-toastify";
import axios from "axios";

import CloseIcon from "@mui/icons-material/Close";

import BodyTypeDataset from "../../assets/data/body_type.json";
import BoobSizeDataset from "../../assets/data/boob_size.json";
import ButtSizeDataset from "../../assets/data/butt_size.json";
import EthnicityDataset from "../../assets/data/ethnicity.json";
import EyeColorDataset from "../../assets/data/eye_color.json";
import HairColorDataset from "../../assets/data/hair_color.json";
import LanguagesDataset from "../../assets/data/languages.json";
import CountryDataset from "../../assets/data/countries.json";
import KnkDataset from "../../assets/data/kinks.json";
import GenderDataset from "../../assets/data/genders.json";

import { fileUpload } from "../../utils";

export default function CreateUser({ user }) {
  const [userId, setUserId] = useState("");
  const [ofLink, setOFLink] = useState();
  const [fullName, setFullName] = useState();
  const [ofPrice, setOFPrice] = useState();
  const [country, setCountry] = useState(CountryDataset[0]);
  const [profileLanguage, setProfileLanguage] = useState(LanguagesDataset[0]);
  const [accountDescription, setAccountDescription] = useState();
  const [primaryProfileImage, setPrimaryProfileImage] = useState();
  const [profileImages, setProfileImages] = useState([]);
  const [hairColor, setHairColor] = useState(HairColorDataset[0]);
  const [eyeColor, setEyeColor] = useState(EyeColorDataset[0]);
  const [bodyType, setBodyType] = useState(BodyTypeDataset[0]);
  const [boobSize, setBoobSize] = useState(BoobSizeDataset[0]);
  const [buttSize, setButtSize] = useState(ButtSizeDataset[0]);
  const [ethnicity, setEthnicity] = useState(EthnicityDataset[0]);
  const [kinks, setKinks] = useState([]);
  const [gender, setGender] = useState(GenderDataset[0]);
  const [tags, setTags] = useState([]);
  const [customTags, setCustomTags] = useState([]);
  const [facebookLink, setFacebookLink] = useState();
  const [instagramLink, setInstagramLink] = useState();
  const [twitterLink, setTwitterLink] = useState();
  const [tiktokLink, setTiktokLink] = useState();
  const [snapchatLink, setSnapchatLink] = useState();
  const [linkedinLink, setLinkedinLink] = useState();
  const [noPhotos, setNoPhotos] = useState();
  const [noVideos, setNoVideos] = useState();
  const [subscription, setSubscription] = useState(null);
  const [messageMe, setMessageMe] = useState({
    label: "No",
    value: false,
  });
  const [freeTrial, setFreeTrial] = useState({
    label: "No",
    value: false,
  });

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");

  useEffect(() => {
    if (user && !userId) setUserId(user._id);
  }, [user]);

  useEffect(() => {
    setKinks(
      KnkDataset.map((item) => ({ ...item, selected: false })).filter(
        (item) => !item.value.includes("Select") && !item.value.includes("All")
      )
    );
    if (id) {
      axios
        .get(`/admin/onlyfans/${id}`)
        .then((res) => {
          if (res.status === 200) {
            setUserId(res.data.user_id);
            setOFLink(res.data.onlyfans_profile);
            setFullName(res.data.name);
            setOFPrice(res.data.onlyfans_price);
            setCountry({
              label: res.data.country,
              value: res.data.country,
            });
            setProfileLanguage({
              label: res.data.profile_language,
              value: res.data.profile_language,
            });
            setAccountDescription(res.data.description);
            setPrimaryProfileImage(res.data.profile_img);
            setProfileImages(res.data.images);
            setHairColor({
              label: res.data.hair_color,
              value: res.data.hair_color,
            });
            setEyeColor({
              label: res.data.eye_color,
              value: res.data.eye_color,
            });
            setBodyType({
              label: res.data.body_type,
              value: res.data.body_type,
            });
            setBoobSize({
              label: res.data.boob_size,
              value: res.data.boob_size,
            });
            setButtSize({
              label: res.data.butt_size,
              value: res.data.butt_size,
            });
            setEthnicity({
              label: res.data.ethnicity,
              value: res.data.ethnicity,
            });
            setKinks((prevKinks) =>
              prevKinks
                .filter((kink) => !res.data.kinks.includes(kink.value))
                .concat(
                  res.data.kinks.map((kink) => ({
                    label: kink,
                    value: kink,
                    selected: true,
                  }))
                )
            );
            setGender({
              label: res.data.gender,
              value: res.data.gender,
            });
            setTags((prevTags) =>
              prevTags
                .filter((tag) => !res.data.tags.includes(tag.value))
                .concat(
                  res.data.tags.map((tag) => ({
                    label: tag,
                    value: tag,
                    selected: true,
                  }))
                )
            );
            setCustomTags(
              res.data.custom_tags.map((tag) => ({
                label: tag,
                value: tag,
                selected: true,
              }))
            );
            setFacebookLink(res.data.facebook);
            setInstagramLink(res.data.instagram);
            setTwitterLink(res.data.twitter);
            setTiktokLink(res.data.tiktok);
            setSnapchatLink(res.data.snapchat);
            setLinkedinLink(res.data.linkedin);
            setNoPhotos(res.data.number_of_photos);
            setNoVideos(res.data.number_of_videos);
            setSubscription(
              res.data.subscription &&
                (res.data.subscription.status === "active" ||
                  res.data.subscription.end_subscription_at > Date.now())
                ? res.data.subscription.plan
                : "Free"
            );
            setMessageMe({
              label: res.data.message_me ? "Yes" : "No",
              value: res.data.message_me,
            });
            setFreeTrial({
              label: res.data.free_trial ? "Yes" : "No",
              value: res.data.free_trial,
            });
          } else {
            toast.error("Profile not found");
          }
        })
        .catch((err) => toast.error(err));
    }

    axios
      .get("/tags")
      .then((res) => {
        if (res.status === 200) {
          let newTags = res.data.tags
            .filter((tag) => !tags.some((item) => item.label === tag.label))
            .map((tag) => {
              return {
                label: tag,
                value: tag,
                selected: false,
              };
            });

          setTags((prevTags) => [...prevTags, ...newTags]);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => toast.error("Something went wrong"));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    let data = {
      user_id: userId,
      name: fullName,
      onlyfans_profile: ofLink,
      onlyfans_price: ofPrice,
      country: country ? country.value : null,
      profile_language: profileLanguage.value,
      description: accountDescription,
      profile_img: primaryProfileImage,
      images: profileImages,
      hair_color: hairColor.value,
      eye_color: eyeColor.value,
      body_type: bodyType.value,
      boob_size: boobSize.value,
      butt_size: buttSize.value,
      ethnicity: ethnicity.value,
      kinks: kinks.filter((kink) => kink.selected).map((kink) => kink.value),
      gender: gender.value,
      tags: tags.filter((tag) => tag.selected).map((tag) => tag.value),
      custom_tags: customTags
        .filter((tag) => tag.selected)
        .map((tag) => tag.value),
      facebook: facebookLink,
      instagram: instagramLink,
      twitter: twitterLink,
      tiktok: tiktokLink,
      snapchat: snapchatLink,
      linkedin: linkedinLink,
      number_of_photos: noPhotos,
      number_of_videos: noVideos,
      message_me: messageMe.value,
      free_trial: freeTrial.value || ofPrice == 0,
      subscription,
    };

    axios
      .post("/admin/onlyfans/add-profile", data)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Successfully added profile");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => toast.error("Something went wrong"));
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    let data = {
      user_id: userId,
      name: fullName,
      onlyfans_profile: ofLink,
      onlyfans_price: ofPrice,
      country: country ? country.value : null,
      profile_language: profileLanguage.value,
      description: accountDescription,
      profile_img: primaryProfileImage,
      images: profileImages,
      hair_color: hairColor.value,
      eye_color: eyeColor.value,
      body_type: bodyType.value,
      boob_size: boobSize.value,
      butt_size: buttSize.value,
      ethnicity: ethnicity.value,
      kinks: kinks.filter((kink) => kink.selected).map((kink) => kink.value),
      gender: gender.value,
      tags: tags.filter((tag) => tag.selected).map((tag) => tag.value),
      custom_tags: customTags
        .filter((tag) => tag.selected)
        .map((tag) => tag.value),
      facebook: facebookLink,
      instagram: instagramLink,
      twitter: twitterLink,
      tiktok: tiktokLink,
      snapchat: snapchatLink,
      linkedin: linkedinLink,
      number_of_photos: noPhotos,
      number_of_videos: noVideos,
      message_me: messageMe.value,
      free_trial: freeTrial.value || ofPrice == 0,
      subscription,
    };

    axios
      .post(`/admin/onlyfans/edit-profile/${id}`, data)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Successfully updated profile");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => toast.error("Something went wrong"));
  };

  useEffect(() => window.refreshDropdown(), [tags, customTags, kinks]);

  const onProfileImgUpload = (e) => {
    toast.info("Uploading profile image...");
    fileUpload(e.target.files[0], (data) => {
      setPrimaryProfileImage(data.secure_url);
    });
  };

  const onAdditionalImgUpload = (e) => {
    toast.info("Uploading additional images...");
    for (let i = 0; i < e.target.files.length; i++) {
      fileUpload(e.target.files[i], (data) => {
        setProfileImages((prev) => [...prev, data.secure_url]);
      });
    }
  };

  return (
    <Fragment>
      <Tabs>
        <TabList className="nav nav-tabs tab-coupon">
          <Tab className="nav-link">Account</Tab>
        </TabList>
        <TabPanel>
          <form className="needs-validation user-add" noValidate="">
            <h4>Account Details</h4>
            <div className="form-group row">
              <label className="col-xl-3 col-md-4">
                <span>*</span> User ID
              </label>
              <input
                className="form-control col-xl-8 col-md-7"
                id="validationCustom0"
                onChange={(e) => setUserId(e.target.value)}
                value={userId}
                type="text"
                required=""
              />
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-md-4">
                <span>*</span> Full Name
              </label>
              <input
                className="form-control col-xl-8 col-md-7"
                id="validationCustom0"
                onChange={(e) => setFullName(e.target.value)}
                value={fullName}
                type="text"
                required=""
              />
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-md-4">Country</label>
              <Dropdown
                onChange={setCountry}
                value={country}
                options={CountryDataset}
              />
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-md-4">
                <span>*</span> Onlyfans Link
              </label>
              <input
                className="form-control col-xl-8 col-md-7"
                id="validationCustom0"
                onChange={(e) => setOFLink(e.target.value)}
                value={ofLink}
                type="text"
                required=""
              />
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-md-4">
                <span>*</span> OnlyFans Price
              </label>
              <input
                className="form-control col-xl-8 col-md-7"
                id="validationCustom0"
                onChange={(e) => setOFPrice(e.target.value)}
                value={ofPrice}
                type="number"
                required=""
              />
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-md-4">
                <span>*</span> Profile Language
              </label>
              <Dropdown
                onChange={setProfileLanguage}
                value={profileLanguage}
                options={LanguagesDataset}
              />
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-md-4">
                <span>*</span> Account Description
              </label>
              <textarea
                className="form-control col-xl-8 col-md-7"
                id="validationCustom0"
                onChange={(e) => setAccountDescription(e.target.value)}
                value={accountDescription}
                required=""
              ></textarea>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-md-4">
                <span>*</span> Hair Color
              </label>
              <Dropdown
                onChange={setHairColor}
                value={hairColor}
                options={HairColorDataset}
              />
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-md-4">
                <span>*</span> Eye Color
              </label>
              <Dropdown
                onChange={setEyeColor}
                value={eyeColor}
                options={EyeColorDataset}
              />
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-md-4">
                <span>*</span> Body Type
              </label>
              <Dropdown
                onChange={setBodyType}
                value={bodyType}
                options={BodyTypeDataset}
              />
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-md-4">
                <span>*</span> Boob Size
              </label>
              <Dropdown
                onChange={setBoobSize}
                value={boobSize}
                options={BoobSizeDataset}
              />
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-md-4">
                <span>*</span> Butt Size
              </label>
              <Dropdown
                onChange={setButtSize}
                value={buttSize}
                options={ButtSizeDataset}
              />
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-md-4">
                <span>*</span> Ethnicity
              </label>
              <Dropdown
                onChange={setEthnicity}
                value={ethnicity}
                options={EthnicityDataset}
              />
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-md-4">
                <span>*</span> Kinks
              </label>
              <select
                className="form-control selectpicker col-xl-8 col-md-7 p-0 m-0"
                multiple
                data-live-search="true"
                onChange={(e) => {
                  let options = e.target.options;

                  let selectedOptions = [];
                  for (let i = 0; i < options.length; i++) {
                    if (options[i].selected) {
                      selectedOptions.push(options[i].value);
                    }
                  }

                  setKinks((prev) =>
                    prev.map((item) => ({
                      label: item.label,
                      value: item.value,
                      selected: selectedOptions.includes(item.value),
                    }))
                  );
                }}
              >
                {kinks.map((item) => (
                  <option
                    key={item.value}
                    value={item.value}
                    selected={item.selected}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-md-4">
                <span>*</span> Gender
              </label>
              <Dropdown
                onChange={setGender}
                value={gender}
                options={GenderDataset}
              />
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-md-4">
                <span>*</span> Tags
              </label>
              <select
                className="form-control selectpicker col-xl-8 col-md-7 p-0 m-0"
                multiple
                data-live-search="true"
                onChange={(e) => {
                  let options = e.target.options;

                  let selectedOptions = [];
                  for (let i = 0; i < options.length; i++) {
                    if (options[i].selected) {
                      selectedOptions.push(options[i].value);
                    }
                  }

                  setTags((prev) =>
                    prev.map((item) => ({
                      label: item.label,
                      value: item.value,
                      selected: selectedOptions.includes(item.value),
                    }))
                  );
                }}
              >
                {tags.map((item) => (
                  <option
                    key={item.value}
                    value={item.value}
                    selected={item.selected}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-md-4">
                <span>*</span> Custom Tags
              </label>
              <div className="row col-xl-8 col-md-7 p-0">
                <div className="col-md-6">
                  <input
                    className="form-control"
                    id="validationCustom0"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        e.stopPropagation();
                        let tag = e.target.value;
                        setCustomTags((prev) => [
                          ...prev,
                          {
                            value: tag,
                            label: tag,
                            selected: true,
                          },
                        ]);
                        e.target.value = "";
                      }
                    }}
                    type="text"
                    required=""
                  />
                </div>
                <div className="col-md-1 p-0"></div>
                <div className="col-md p-0">
                  <select
                    className="form-control selectpicker p-0 m-0"
                    multiple
                    data-live-search="true"
                    onChange={(e) => {
                      let options = e.target.options;

                      let selectedOptions = [];
                      for (let i = 0; i < options.length; i++) {
                        if (options[i].selected) {
                          selectedOptions.push(options[i].value);
                        }
                      }

                      setCustomTags((prev) =>
                        prev.map((item) => ({
                          label: item.label,
                          value: item.value,
                          selected: selectedOptions.includes(item.value),
                        }))
                      );
                    }}
                  >
                    {customTags.map((item) => (
                      <option
                        value={item.value}
                        key={item.value}
                        selected={item.selected}
                      >
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-md-4">Facebook</label>
              <input
                className="form-control col-xl-8 col-md-7"
                id="validationCustom0"
                onChange={(e) => setFacebookLink(e.target.value)}
                value={facebookLink}
                type="text"
                required=""
              />
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-md-4">Instagram</label>
              <input
                className="form-control col-xl-8 col-md-7"
                id="validationCustom0"
                onChange={(e) => setInstagramLink(e.target.value)}
                value={instagramLink}
                type="text"
                required=""
              />
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-md-4">Twitter</label>
              <input
                className="form-control col-xl-8 col-md-7"
                id="validationCustom0"
                onChange={(e) => setTwitterLink(e.target.value)}
                value={twitterLink}
                type="text"
                required=""
              />
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-md-4">Tiktok</label>
              <input
                className="form-control col-xl-8 col-md-7"
                id="validationCustom0"
                onChange={(e) => setTiktokLink(e.target.value)}
                value={tiktokLink}
                type="text"
                required=""
              />
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-md-4">Snapchat</label>
              <input
                className="form-control col-xl-8 col-md-7"
                id="validationCustom0"
                onChange={(e) => setSnapchatLink(e.target.value)}
                value={snapchatLink}
                type="text"
                required=""
              />
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-md-4">Linkedin</label>
              <input
                className="form-control col-xl-8 col-md-7"
                id="validationCustom0"
                onChange={(e) => setLinkedinLink(e.target.value)}
                value={linkedinLink}
                type="text"
                required=""
              />
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-md-4">
                <span>*</span> Number of Photos
              </label>
              <input
                className="form-control col-xl-8 col-md-7"
                id="validationCustom0"
                onChange={(e) => setNoPhotos(e.target.value)}
                value={noPhotos}
                type="number"
                required=""
              />
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-md-4">
                <span>*</span> Number of Videos
              </label>
              <input
                className="form-control col-xl-8 col-md-7"
                id="validationCustom0"
                onChange={(e) => setNoVideos(e.target.value)}
                value={noVideos}
                type="number"
                required=""
              />
            </div>
            <div className="attribute-blocks mt-4">
              <h5 className="f-w-600 mb-3">Subscription</h5>
              <div className="ml-5">
                <div className="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                  <label className="d-block">
                    <input
                      className="radio_animated"
                      type="radio"
                      onChange={(e) => setSubscription("Advanced")}
                      checked={subscription === "Advanced"}
                    />
                    Advanced
                  </label>
                </div>
              </div>
              <div className="ml-5 mt-2">
                <div className="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                  <label className="d-block">
                    <input
                      className="radio_animated"
                      type="radio"
                      onChange={(e) => setSubscription("Premium")}
                      checked={subscription === "Premium"}
                    />
                    Premium
                  </label>
                </div>
              </div>
              <div className="ml-5 mt-2">
                <div className="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                  <label className="d-block">
                    <input
                      className="radio_animated"
                      type="radio"
                      onChange={(e) => setSubscription("Free")}
                      checked={subscription === "Free"}
                    />
                    Free
                  </label>
                </div>
              </div>
            </div>
            <div className="attribute-blocks mt-4">
              <h5 className="f-w-600 mb-3">Free Trial</h5>
              <div className="ml-5">
                <div className="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                  <label className="d-block">
                    <input
                      className="radio_animated"
                      type="radio"
                      onChange={(e) =>
                        setFreeTrial({
                          label: "Yes",
                          value: true,
                        })
                      }
                      checked={freeTrial.value}
                    />
                    Yes
                  </label>
                </div>
              </div>
              <div className="ml-5 mt-2">
                <div className="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                  <label className="d-block">
                    <input
                      className="radio_animated"
                      type="radio"
                      onChange={(e) =>
                        setFreeTrial({
                          label: "No",
                          value: false,
                        })
                      }
                      checked={!freeTrial.value}
                    />
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="attribute-blocks mt-4">
              <h5 className="f-w-600 mb-3">Message Me</h5>
              <div className="ml-5">
                <div className="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                  <label className="d-block">
                    <input
                      className="radio_animated"
                      type="radio"
                      onChange={(e) =>
                        setMessageMe({
                          label: "Yes",
                          value: true,
                        })
                      }
                      checked={messageMe.value}
                    />
                    Yes
                  </label>
                </div>
              </div>
              <div className="ml-5 mt-2">
                <div className="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                  <label className="d-block">
                    <input
                      className="radio_animated"
                      type="radio"
                      onChange={(e) =>
                        setMessageMe({
                          label: "No",
                          value: false,
                        })
                      }
                      checked={!messageMe.value}
                    />
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group row mt-4">
              <label className="col-xl-3 col-md-4">
                <span>*</span> Profile Image
              </label>
              <input
                className="form-control col-xl-8 col-md-7"
                id="validationCustom0"
                onChange={onProfileImgUpload}
                type="file"
                required=""
              />
            </div>
            {primaryProfileImage && (
              <div
                className="mt-5 mb-5"
                src={primaryProfileImage}
                style={{
                  position: "relative",
                  backgroundImage: `url(${primaryProfileImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center top, center center",
                  backgroundRepeat: "no-repeat",
                  width: "250px",
                  height: "250px",
                }}
              >
                <div
                  onClick={() => setPrimaryProfileImage(null)}
                  className="pointer"
                  style={{
                    backgroundColor: "red",
                    position: "absolute",
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    padding: "3px",
                    top: "-15px",
                    right: "-15px",
                  }}
                >
                  <CloseIcon className="text-white" fontSize="medium" />
                </div>
              </div>
            )}
            <div className="form-group row mt-4">
              <label className="col-xl-3 col-md-4">
                <span>*</span> Additional Images
              </label>
              <input
                className="form-control col-xl-8 col-md-7"
                id="validationCustom0"
                type="file"
                onChange={onAdditionalImgUpload}
                multiple
                required=""
              />
            </div>
            <div
              className="row g-4 mt-4 m-0"
              style={{
                position: "relative",
              }}
            >
              {profileImages.map((img) => (
                <div
                  onClick={() =>
                    setProfileImages((prev) =>
                      prev.filter((image) => img !== image)
                    )
                  }
                  className="mr-4"
                  src={img}
                  style={{
                    position: "relative",
                    backgroundImage: `url(${img})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center top, center center",
                    backgroundRepeat: "no-repeat",
                    width: "250px",
                    height: "250px",
                  }}
                >
                  <div
                    className="pointer"
                    style={{
                      backgroundColor: "red",
                      position: "absolute",
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      padding: "3px",
                      top: "-15px",
                      right: "-15px",
                    }}
                  >
                    <CloseIcon className="text-white" fontSize="medium" />
                  </div>
                </div>
              ))}
            </div>
          </form>
        </TabPanel>
      </Tabs>
      <div className="pull-right">
        <button
          onClick={id ? handleUpdate : handleSubmit}
          type="button"
          className="btn btn-primary"
        >
          {id ? "Update" : "Submit"}
        </button>
      </div>
    </Fragment>
  );
}

const Dropdown = ({
  options = [
    {
      label: "",
      value: "",
    },
  ],
  onChange,
  value,
}) => {
  return (
    <select
      className="form-control col-xl-8 col-md-7"
      onChange={(e) =>
        onChange(options.find((option) => option.value === e.target.value))
      }
      value={value && value.value ? value.value : options[0].value}
    >
      {options.map((item) => (
        <option key={item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
  );
};
