import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

import "react-toastify/dist/ReactToastify.css";
import "./index.scss";

import App from "./components/app";
import Dashboard from "./components/dashboard";
import Login from "./components/auth/login";
import Logout from "./components/auth/logout";

import Users from "./pages/users";
import PromoOnlyfans from "./pages/promo-onlyfans";
import Onlyfans from "./pages/onlyfans";
import CreateOnlyfan from "./pages/onlyfans/create";
import Likes from "./pages/likes";
import Reports from "./pages/reports";
import Tags from "./pages/tags";
import CreateTag from "./pages/tags/create";
import CustomTags from "./pages/custom-tags";

axios.defaults.baseURL = "/api";
axios.defaults.validateStatus = false;

function Root() {
  const [isAuth, setAuth] = useState(Boolean(localStorage.getItem("isAuth")));
  const [user, setUser] = useState(null);

  useEffect(() => {
    axios
      .get("/auth/user")
      .then((res) => {
        if (res.status === 200) {
          setAuth(res.data.user.isAuthenticated);
          localStorage.setItem("isAuth", res.data.user.isAuthenticated);
          setUser(res.data.user);
        } else {
          localStorage.removeItem("isAuth");
          setAuth(false);
          setUser(null);
        }
      })
      .catch((err) => toast.error(err));
  }, [localStorage]);

  return (
    <BrowserRouter basename={"/"}>
      <Switch>
        {isAuth && <Route exact path="/logout" component={Logout} />}

        {!isAuth && <Route exact path="/auth/login" component={Login} />}

        {isAuth && (
          <App user={user}>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/users" component={Users} />
            <Route exact path="/promo-onlyfans" component={PromoOnlyfans} />
            <Route exact path="/onlyfans" component={Onlyfans} />
            <Route
              exact
              path="/onlyfans/create"
              render={(props) => <CreateOnlyfan {...props} user={user} />}
            />
            <Route exact path="/likes" component={Likes} />
            <Route exact path="/reports" component={Reports} />
            <Route exact path="/tags" component={Tags} />
            <Route exact path="/tags/create" component={CreateTag} />
            <Route exact path="/custom-tags" component={CustomTags} />
          </App>
        )}

        {!isAuth && <Route path="/" component={Login} />}
      </Switch>
    </BrowserRouter>
  );
}

ReactDOM.render(
  <>
    <ToastContainer />
    <Root />
  </>,
  document.getElementById("root")
);
