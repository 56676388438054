import React, { Fragment, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import axios from "axios";

import DeleteIcon from "@mui/icons-material/Delete";

import Breadcrumb from "../../components/common/breadcrumb";

export default function Tags() {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [tags, setTags] = useState([]);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("all");

  const onSearch = (e, reset) => {
    axios
      .get(`/admin/tags/${reset ? 1 : page}/${search}`)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setTags(
            res.data.tags.map((tag) => ({
              ...tag,
              actions: "",
            }))
          );
          setCount(res.data.count);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => toast.error(err));
  };

  useEffect(() => onSearch(), [page]);

  useEffect(() => {
    setCount((prevCount) => (count !== undefined ? count : prevCount));
  }, [count, setCount]);

  const onDelete = (selectedTag) => {
    const confirm = window.confirm("Are you sure you want to delete this tag?");

    if (confirm) {
      axios
        .delete(`/admin/tags/${selectedTag}`)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Tag deleted successfully");
            setTags(tags.filter((tag) => tag.tag !== selectedTag));
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => toast.error("Something went wrong"));
    }
  };

  return (
    <Fragment>
      <Breadcrumb title="Tags List" parent="Tags" />
      <div className="container-fluid">
        <div className="card">
          <div className="d-flex card-header">
            <h5>Tag Details</h5>
            <div className="flex-grow-1"></div>
            <div className="d-flex align-items-center">
              <span className="mr-2">Search:</span>
              <input
                type="text"
                class="form-control"
                style={{ width: 250 }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <button
                className="btn btn-primary ml-2"
                onClick={(e) => onSearch(e, true)}
              >
                Search
              </button>
            </div>
          </div>

          <div className="card-body">
            <div
              style={{ height: 630, width: "100%" }}
              id="batchDelete"
              className="category-table user-list order-table coupon-list-delete"
            >
              <DataGrid
                rowHeight={60}
                rows={tags}
                columns={
                  tags[0]
                    ? Object.keys(tags[0]).map((key) => ({
                        field: key,
                        headerName: key
                          .replace(/_/g, " ")
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1) + " "
                          ),
                        renderCell: (row) => {
                          if (key === "actions") {
                            return (
                              <div className="align-items-center">
                                <DeleteIcon
                                  className="text-danger pointer"
                                  onClick={() => onDelete(row.row.tag)}
                                />
                              </div>
                            );
                          } else {
                            return row[key];
                          }
                        },
                        width: 200,
                        editable: true,
                        sortable: true,
                      }))
                    : []
                }
                pagination
                pageSize={10}
                rowsPerPageOptions={[10]}
                rowCount={count}
                paginationMode="server"
                onPageChange={(page) => {
                  setPage(page + 1);
                  setLoading(true);
                }}
                loading={loading}
                autoWidth={true}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
