import React, { Fragment, useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";

export default function CreateUser() {
  const [tag, setTag] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!tag) {
      toast.error("Tag is required");
      return;
    }

    axios
      .post("/admin/tags", {
        tag,
      })
      .then((res) => {
        if (res.status === 200) {
          setTag("");
          toast.success("Tag added successfully!");
        } else {
          toast.error("Error adding tag!");
        }
      })
      .catch((err) => toast.error(err));
  };

  return (
    <Fragment>
      <form className="needs-validation user-add" noValidate="">
        <h4>Tag Details</h4>
        <div className="form-group row">
          <label className="col-xl-3 col-md-4">
            <span>*</span> Tag
          </label>
          <input
            className="form-control col-xl-8 col-md-7"
            id="validationCustom0"
            onChange={(e) => setTag(e.target.value)}
            value={tag}
            type="text"
            required=""
          />
        </div>
      </form>
      <div className="pull-right">
        <button
          onClick={handleSubmit}
          type="button"
          className="btn btn-primary"
        >
          Submit
        </button>
      </div>
    </Fragment>
  );
}
