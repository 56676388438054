import React, { Component } from "react";

export class Dashboard extends Component {

  componentDidMount() {
    this.props.history.push("/users");
  }

  render() {
    return <></>;
  }
}

export default Dashboard;
