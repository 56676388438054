import React, { useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";

export default function Logout() {
  useEffect(() => {
    toast.info("Logging out...");

    axios
      .get("/auth/logout")
      .then(() => {
        toast.success("Logged out successfully");
        window.localStorage.setItem("isAuth", false);
        window.location.assign("/");
      })
      .catch((err) => toast.error(err));
  }, []);

  return <></>;
}
