import React, { useState } from "react";
import Sidebar from "./common/sidebar_components/sidebar";
import Footer from "./common/footer";
import Header from "./common/header_components/header";

export default function App({ children, user }) {
  const [divName, setDivName] = useState("RTL");

  const changeRtl = (divName) => {
    if (divName === "RTL") {
      document.body.classList.add("rtl");
      setDivName("LTR");
    } else {
      document.body.classList.remove("rtl");
      setDivName("RTL");
    }
  };

  return (
    <div>
      <div className="page-wrapper">
        <Header />
        <div className="page-body-wrapper">
          <Sidebar user={user}/>
          <div className="page-body">{children}</div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
