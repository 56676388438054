import { User, Gift, UserCheck, Heart, Flag, Tag, LogOut } from "react-feather";

export const MENUITEMS = [
  {
    title: "Users",
    path: "/users",
    icon: User,
    type: "link",
    active: false,
  },
  {
    title: "Promo Onlyfans",
    path: "/promo-onlyfans",
    icon: Gift,
    type: "link",
    active: false,
  },
  {
    title: "Onlyfans",
    icon: UserCheck,
    type: "sub",
    active: false,
    children: [
      { path: "/onlyfans", title: "Onlyfans", type: "link" },
      { path: "/onlyfans/create", title: "Create Onlyfan", type: "link" },
    ],
  },
  {
    title: "Likes",
    path: "/likes",
    icon: Heart,
    type: "link",
    active: false,
  },
  {
    title: "Reports",
    path: "/reports",
    icon: Flag,
    type: "link",
    active: false,
  },
  {
    title: "Tags",
    icon: Tag,
    type: "sub",
    active: false,
    children: [
      { path: "/tags", title: "Tags", type: "link" },
      { path: "/tags/create", title: "Create Tag", type: "link" },
    ],
  },
  {
    title: "Custom Tags",
    path: "/custom-tags",
    icon: Tag,
    type: "link",
    active: false,
  },
  {
    title: "Logout",
    path: "/logout",
    icon: LogOut,
    type: "link",
    active: false,
  },
];
