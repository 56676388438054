import React, { Fragment } from "react";
import LoginTabset from "./loginTabset";

export default function Login() {
  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="authentication-box">
          <div className="w-50 p-0 card-right">
            <div className="card tab2-card">
              <div className="card-body">
                <LoginTabset />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
