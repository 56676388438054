import React, { Fragment } from "react";

import Breadcrumb from "../../components/common/breadcrumb";
import TabsetTag from "./tabset-tag";

export default function CreateTag() {
  return (
    <Fragment>
      <Breadcrumb title="Create Tag" parent="Tags" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Add Tag</h5>
              </div>
              <div className="card-body">
                <TabsetTag />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
