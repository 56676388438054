import React, { Fragment, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import axios from "axios";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import Breadcrumb from "../components/common/breadcrumb";

export default function Onlyfans() {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("all");

  const onSearch = (e, reset) => {
    axios
      .get(`/admin/promo-onlyfans/${reset ? 1 : page}/${search}`)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setUsers(
            res.data.users.map((user) => ({
              ...user,
              actions: "",
            }))
          );
          setCount(res.data.count);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => toast.error(err));
  };

  useEffect(() => onSearch(), [page]);

  useEffect(() => {
    setCount((prevCount) => (count !== undefined ? count : prevCount));
  }, [count, setCount]);

  const onDelete = (id) => {
    const confirm = window.confirm(
      "Are you sure you want to remove promo to this profile?"
    );

    if (confirm) {
      axios
        .delete(`/admin/promo-onlyfans/${id}`)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Profile promo deleted successfully");
            setUsers(users.filter((user) => user.id !== id));
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => toast.error("Something went wrong"));
    }
  };

  return (
    <Fragment>
      <Breadcrumb title="Onlyfans List" parent="Onlyfans" />
      <div className="container-fluid">
        <div className="card">
          <div className="d-flex card-header">
            <h5>Onlyfan Details</h5>
            <div className="flex-grow-1"></div>
            <div className="d-flex align-items-center">
              <span className="mr-2">Search:</span>
              <input
                type="text"
                class="form-control"
                style={{ width: 250 }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <button
                className="btn btn-primary ml-2"
                onClick={(e) => onSearch(e, true)}
              >
                Search
              </button>
            </div>
          </div>
          <div className="card-body">
            <div
              style={{ height: 630, width: "100%" }}
              id="batchDelete"
              className="category-table user-list order-table coupon-list-delete"
            >
              <DataGrid
                rowHeight={60}
                rows={users}
                columns={
                  users[0]
                    ? Object.keys(users[0]).map((key) => ({
                        field: key,
                        headerName: key
                          .replace(/_/g, " ")
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1) + " "
                          ),
                        renderCell: (row) => {
                          if (key === "profile_img") {
                            return (
                              <img
                                width={50}
                                height={50}
                                src={
                                  users.find((user) => user.id === row.id)
                                    .profile_img
                                }
                                alt="Photo related to an onlyfan"
                              />
                            );
                          } else if (key === "actions") {
                            return (
                              <div className="align-items-center">
                                <DeleteIcon
                                  className="text-danger pointer"
                                  onClick={() => onDelete(row.id)}
                                />
                                <EditIcon
                                  className="text-success pointer"
                                  onClick={() =>
                                    window.location.assign(
                                      `/onlyfans/create?id=${row.id}`
                                    )
                                  }
                                />
                              </div>
                            );
                          } else {
                            return row[key];
                          }
                        },
                        width: 200,
                        editable: true,
                        sortable: true,
                      }))
                    : []
                }
                pagination
                pageSize={10}
                rowsPerPageOptions={[10]}
                rowCount={count}
                paginationMode="server"
                onPageChange={(page) => {
                  setPage(page + 1);
                  setLoading(true);
                }}
                loading={loading}
                autoWidth={true}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
