import React, { Fragment } from "react";

import Breadcrumb from "../../components/common/breadcrumb";
import TabsetOnlyfan from "./tabset-onlyfan";

export default function CreateUser({ user }) {
  return (
    <Fragment>
      <Breadcrumb title="Create Onlyfan" parent="Onlyfans" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Add Onlyfan</h5>
              </div>
              <div className="card-body">
                <TabsetOnlyfan user={user} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
