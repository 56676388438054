import { toast } from "react-toastify";
import axios from "axios";

const onUpload = (
  doc,
  onSuccess,
  onUploadProgress = () => {},
  onError = () => {}
) => {
  const data = new FormData();
  data.append("file", doc);

  axios
    .post("/upload-file", data, {
      onUploadProgress: (p) => {
        let progress = p.loaded / p.total;
        progress = progress * 100;
        onUploadProgress(progress);
      },
    })
    .then(({ data }) => {
      if (data.error) {
        toast.error(data.error.message);
        onError();
      }
      onSuccess(data);
    })
    .catch((err) => {
      toast.error(err);
      onError();
    });
};

export default onUpload;
