import React, { Fragment, useState } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { toast } from "react-toastify";
import { User } from "react-feather";
import { withRouter } from "react-router-dom";
import axios from "axios";

function LoginTabset() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const checkEmail = (email) => {
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(regexEmail)) {
      return true;
    } else {
      return false;
    }
  };

  const onLogin = (e) => {
    e.preventDefault();

    if (!email) {
      toast.error("Email is required");
      return;
    }

    if (!checkEmail(email)) {
      toast.error("Email is not valid");
      return;
    }

    if (!password) {
      toast.error("Password is required");
      return;
    }

    axios
      .post("/admin/signin", {
        email,
        password,
        rememberMe,
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Successfully logged in");
          localStorage.setItem("isAuth", res.data.isAuth);
          window.location.assign("/");
        } else {
          toast.error(res.data.message);
        }
      });
  };

  return (
    <Fragment>
      <Tabs>
        <TabList className="nav nav-tabs tab-coupon">
          <Tab className="nav-link">
            <User />
            Login
          </Tab>
        </TabList>

        <TabPanel>
          <form className="form-horizontal auth-form">
            <div className="form-group">
              <input
                required=""
                name="login[username]"
                type="email"
                className="form-control"
                placeholder="Email"
                id="exampleInputEmail1"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                required=""
                name="login[password]"
                type="password"
                className="form-control"
                placeholder="Password"
                id="exampleInputPassword1"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="d-flex">
              <div className="col mt-2">
                <input type="checkbox" className="custom-control-input" />
                <label className="d-block">
                  <input
                    className="checkbox_animated"
                    id="chk-ani2"
                    type="checkbox"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  Remember Me
                </label>
              </div>
              <button
                className="btn btn-primary m-0"
                type="submit"
                onClick={onLogin}
              >
                Login
              </button>
            </div>
          </form>
        </TabPanel>
      </Tabs>
    </Fragment>
  );
}

export default withRouter(LoginTabset);
